export const ROUTE_PATHS = {
  HOME: "/",
  ORDERSEARCH: "/OrderSearch",
  OFFERSEARCH: "/OfferSearch",
  TRACKER: "/tracker",
  PROMOTION: "/promotion",
  SUBMISSION_METHOD: "/method",
  REGISTER: "/register",
  UPLOAD: "/upload",
  THANKS: "/thanks",
  CONTACT: "/contact",
  CONTACT_SUCCESS: "/contact_success",
  STATUS: "/status",
  FAQ: "/faq",
  LOOKUP: "/lookup",
  HOMEPARAMS: "/exclusiveCode=:exclusiveCode?",
  EXCLUSIVECODE: "/exclusiveCode",
  BROADBAND: "/broadband",
  T2T: "/t2t"
}
